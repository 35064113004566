import {getSubdomain} from './uri';
import * as Storage from './storage';

export const getAppInfo = () => {
  const appName = getSubdomain(window.location.hostname);

  const session = Storage.getItem('session');
  const apiKey = session?.apiKey;

  return [appName, apiKey];
};

export const isLoggedIn = () => {
  const session = Storage.getItem('session');
  console.log('isLoggedIn', session);
  return !!session?.sessionId && !!session?.apiKey;
};
