export const setItem = (storageKey, value) => {
  const serializedValue =
    typeof value === 'object' ? JSON.stringify(value) : value;

  if (!storageKey || !value) {
    throw 'Empty storageKey or value';
  }

  window.localStorage[storageKey] = serializedValue;

  console.log('setItem', storageKey, serializedValue);
};

export const getItem = (storageKey) => {
  let deserializedValue = null;
  let value = null;

  try {
    value = window.localStorage[storageKey];
    deserializedValue = JSON.parse(value);
  } catch (err) {
    deserializedValue = value; // getItem value is of type String
  }

  console.log('getItem', storageKey, deserializedValue);

  return deserializedValue;
};
