/* eslint-disable react/jsx-filename-extension */

/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React from 'react';
import fetch from 'isomorphic-fetch';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
} from '@apollo/client';
import {createAuthLink} from 'aws-appsync-auth-link';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';
import {cache, defaultOptions} from '@macanta/config/apolloConfig';
import {getAppInfo} from '@macanta/utils/app';

const AppSyncConfig = {
  graphqlEndpoint: process.env.GATSBY_API_URL,
  region: process.env.AWS_REGION,
  authenticationType: process.env.AWS_AUTHENTICATION_TYPE,
  apiKey: process.env.GATSBY_API_KEY,
};

const url = AppSyncConfig.graphqlEndpoint;
const region = AppSyncConfig.region;
const auth = {
  type: AppSyncConfig.authenticationType,
  apiKey: AppSyncConfig.apiKey,
};

const retryLink = new RetryLink();

const appLink = new ApolloLink(async (operation, forward) => {
  const [appName, apiKey] = getAppInfo();

  console.log('appName & apiKey', appName, apiKey);

  const mutationKey = operation.variables.__mutationkey;

  if (mutationKey) {
    const mutationVariables = operation.variables[mutationKey];

    operation.variables[mutationKey] = {
      appName,
      apiKey,
      ...mutationVariables,
    };
  } else {
    operation.variables = {appName, apiKey, ...operation.variables};
  }

  return forward(operation);
});

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  const mutationKey = operation.variables.__mutationkey;

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      const {message, locations, path} = err;
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        `Operation error name: ${operation.operationName}`,
      );

      // SentryConfig.captureException(err);
      console.error('ERROR (captured by apollo client): ' + err.message);
    });
  }

  if (networkError && mutationKey) {
    console.log(`[Network error on mutation]: ${networkError}`);

    // hasConnection().then((isConnected) => {
    //   if (!isConnected) {
    //     showNoConnectionAlert();
    //   }
    // });
  }
});

const link = ApolloLink.from([
  errorLink,
  retryLink,
  createAuthLink({url, region, auth}),
  appLink,
  new HttpLink({uri: url, fetch}),
]);

const getClient = () => {
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  });

  return client;
};

const client = getClient();

const RootElementWrapper = ({element}) => {
  return <ApolloProvider client={client}>{element}</ApolloProvider>;
};

export const wrapRootElement = RootElementWrapper;
